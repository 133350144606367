import debounce from "lodash/debounce";
import { path, pathOr } from "ramda";

import { logout } from "../auth/actions/actions";
import { notifyError } from "../components/notifications/notificationService";

const unauthorizedNotification = () => notifyError("You are not authorized.");

const debounceOptions = {
  maxWait: 500,
  leading: true,
  trailing: false,
};

const DEBOUNCE_WAIT = 1000;

const unauthorizedNotificationDebounced = debounce(
  unauthorizedNotification,
  DEBOUNCE_WAIT,
  debounceOptions
);

export const allowedMessagesFor401 = [
  "incorrect old password",
  "invalid totp code",
  "otp challenge verification failed",
  "totp code already used",
];

export const allowedMessagesFor403 = [
  "could not delete yourself",
  "could not deactivate yourself",
  "user not associated with any identity pool",
  "password already set",
];

export const unauthorizedRedirectAxios = err => {
  if (
    path(["response", "status"], err) === 401 &&
    !allowedMessagesFor401.includes(path(["response", "data", "error"], err)) &&
    !pathOr("", ["response", "headers", "www-authenticate"], err).includes(
      "insufficient_user_authentication"
    )
  ) {
    logout();
  }

  if (
    path(["response", "status"], err) === 403 &&
    !allowedMessagesFor403.includes(path(["response", "data", "error"], err))
  ) {
    unauthorizedNotificationDebounced();
  }

  return Promise.reject(err);
};
