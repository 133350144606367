import React from "react";
import { useNavigate } from "react-router";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { Edit } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Attribute } from "@cloudentity/acp-root";

import correlationIcon from "../../../../assets/images/icons/identities/provisioning-correlation-icon.svg";
import identityPoolIcon from "../../../../assets/images/logos/idp/identity-pool.svg";
import saIcon from "../../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";
import IconTooltip from "../../../../common/components/IconTooltip";
import { useFormContext } from "../../../../common/utils/forms/Form";
import { useListWorkspacePools } from "../../../services/adminIdentityPoolsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { IdpUiModelType, providers } from "../identities.utils";
import AuthnContextAutocomplete from "./AuthnContextAutocomplete";
import TypeSelect from "./TypeSelect";

const useStyles = makeStyles()(() => ({
  container: {
    marginBottom: 36,
  },
  header: {
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
  },
  logo: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 24,
    zIndex: 1,
    borderLeft: "1px solid white",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 144px 1fr",
  },
  logoSA: {
    width: 30,
    backgroundColor: "white",
  },
}));

interface Props {
  method: IdpUiModelType;
  attributes: Attribute[];
}

export default function IdentitiesDetailsProvisioningIdentifiers({ method, attributes }: Props) {
  const { cx, classes } = useStyles();
  const theme = useTheme();

  const { form } = useFormContext();
  const navigate = useNavigate();

  const listWorkspacePoolsQuery = useListWorkspacePools({
    wid: method.authorization_server_id!,
  });

  const idpData = providers.find(p => p.method === method.method);
  const pool = form.watch("pool");

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.header}>
        Identifier Correlation
        <IconTooltip
          id="identifier-label"
          width="475"
          title={
            <div>
              <div>
                <b>The rule for target user record identification</b>
              </div>
              <p style={{ lineHeight: "18px" }}>
                It holds criteria to find a target user record in the identity pool by matching
                upstream authentication context attributes against the attributes specified in the
                identity pool.
              </p>
            </div>
          }
        />
      </Typography>
      <div>
        <div className={classes.grid}>
          <div style={{ position: "relative" }}>
            <img src={saIcon} alt="logo" className={cx(classes.logo, classes.logoSA)} />

            <CardWithIconAndTitle
              img={idpData?.icon}
              title={idpData?.name ?? ""}
              subtitle="Incoming User/Authentication Context"
              id="identities-mappings-target-tile"
              noPadding
              noBorder
              style={{
                backgroundColor: "transparent",
                borderLeft: "solid 1px white",
                borderRadius: 4,
              }}
              subtitleStyle={{
                color: theme.palette.secondary.light,
                marginLeft: 28,
                borderRadius: 16,
              }}
              titleStyle={{ marginLeft: 28 }}
              noIconWidth
            />
          </div>
          <div />
          <div style={{ display: "flex" }}>
            <CardWithIconAndTitle
              img={identityPoolIcon}
              title={pool?.name ?? ""}
              subtitle={
                listWorkspacePoolsQuery.data?.pools?.length === 1 ? "Users" : "Identity Pool"
              }
              id="identities-mappings-target-tile"
              noPadding
              noBorder
              style={{ zIndex: 1, backgroundColor: "transparent" }}
              subtitleStyle={{ color: theme.palette.secondary.light, marginLeft: 8 }}
              titleStyle={{ marginLeft: 8 }}
              noIconWidth
            />
            {pool && (
              <IconButton
                size="large"
                color="primary"
                onClick={() =>
                  pool.groupType === "workspace"
                    ? navigate(`/${pool.workspace_id}/pools/${pool.id}/users`)
                    : navigate(`/identity-pools/pools/${pool.id}/users`)
                }
              >
                <Edit />
              </IconButton>
            )}
          </div>
        </div>

        <div className={classes.grid} style={{ paddingTop: 16 }}>
          <div>
            <AuthnContextAutocomplete
              id="user-identifier-source"
              name="user.identifier.source"
              options={attributes}
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <img src={correlationIcon} alt="correlation-icon" style={{ width: 50 }} />
          </div>
          <div>
            <TypeSelect id="user-identifier-type" name="user.identifier.type" />
          </div>
        </div>
      </div>
    </div>
  );
}
