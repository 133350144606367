import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { lensPath, map, over, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import TextFieldReadOnly from "../../../common/utils/forms/TextFieldReadOnly";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelAuth0Type, providers } from "./identities.utils";

const id = "identities-configuration-auth0";

interface Props {
  provider: IdpUiModelAuth0Type;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelAuth0Type) => void;
  onDelete?: (idp: IdpUiModelAuth0Type) => void;
}

export default function IdentitiesConfigurationAuth0({
  provider,
  server,
  updateProgress,
  inEdit,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(
    () => over(lensPath(["settings", "scopes"]), scopes => scopes || [], provider),
    [provider]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id,
    initialValues: data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (provider, data) => ({ ...provider, ...amrToResponse(data), ...data });

  const providerMapData = providers.find(p => p.method === "auth0");

  useEffect(() => {
    onInit &&
      onInit(() => form.handleSubmit(data => onSubmit && onSubmit(submitFn(provider, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <Form form={form}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />
            <TextFieldRequired
              name="settings.domain"
              label="Domain"
              defaultValue={pathOr("", ["settings", "domain"], provider)}
              rules={{
                validate: {
                  validDomain: validators.validDomain({ label: "Value" }),
                },
              }}
            />
            <TextFieldRequired
              name="settings.client_id"
              label="Client ID"
              defaultValue={pathOr("", ["settings", "client_id"], provider)}
              withCopy
              inputProps={{ autoComplete: "off" }}
            />
            <TextFieldRequired
              name="credentials.client_secret"
              label="Client Secret"
              defaultValue={pathOr("", ["credentials", "client_secret"], provider)}
              toggleVisibility
              defaultVisibility={false}
              withCopy
              inputProps={{ autoComplete: "off" }}
            />
            <TextFieldReadOnly name="redirectUrl" label="Redirect URL" withCopy />
            <CommonIdpConfig id={id} inEdit={inEdit} data={provider} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                <AutocompleteField
                  name="static_amr"
                  label="Authentication Method Reference"
                  helperText="If set overwrites AMR obtained from this authentication method"
                  defaultValue={pathOr([], ["static_amr"], provider)}
                  options={map(prop("value"), amrOptions)}
                  getOptionLabel={getAMRLabel}
                  multiple
                />
                <CheckboxField name="settings.get_user_info" label="Get user info" />
                <CheckboxField
                  name="settings.send_login_hint"
                  label={
                    <span>
                      Send <code>login_hint</code>
                    </span>
                  }
                  helperText={
                    <span>
                      Sends the entered identifier as a <code>login_hint</code> to the IDP
                    </span>
                  }
                />
                {serverSSOEnabled && <SSOIDPSettings />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </Form>
  );
}
