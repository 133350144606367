import React, { useMemo } from "react";
import { FieldValues } from "react-hook-form";

import Grid from "@mui/material/Grid";
import omit from "lodash/omit";

import {
  Attribute,
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
  JITProvisioningAdminRoleTypeEnum,
  JITSettings,
  RevokeTenantRoleRequestRoleEnum,
  RevokeTenantRoleRequestTypeEnum,
  TenantRoles,
  TenantRoleSubjectTypeEnum,
} from "@cloudentity/acp-admin";
import { PoolResponse } from "@cloudentity/acp-identity";

import iconEnabled from "../../../../assets/images/icons/identities/user-provisioning.svg";
import iconDisabled from "../../../../assets/images/icons/identities/user-x.svg";
import { getTenantId } from "../../../../common/api/paths";
import Progress from "../../../../common/components/Progress";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import CardRadioGroupField from "../../../../common/utils/forms/CardRadioGroupField";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import { useUpdateIDPMutation } from "../../../services/adminIDPsMutations";
import { useGetPool } from "../../../services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import {
  useGrantTenantRoleMutation,
  useRevokeTenantRoleMutation,
} from "../../../services/adminRolesMutations";
import { useListTenantRoles } from "../../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { IdpUiModelType, transformIdpToAPI } from "../identities.utils";
import IdentitiesDetailsProvisioningConfig from "./IdentitiesDetailsProvisioningConfig";

enum VerificationMode {
  UNVERIFIED = "unverified",
  VERIFIED = "verified",
  DISCOVERY = "oidc_discovery",
}

const addressesTypeMapper = {
  email_verified: { type: "email", verification_mode: VerificationMode.VERIFIED },
  email_unverified: { type: "email", verification_mode: VerificationMode.UNVERIFIED },
  email_oidc_discovery: { type: "email", verification_mode: VerificationMode.DISCOVERY },
  phone_verified: { type: "mobile", verification_mode: VerificationMode.VERIFIED },
  phone_unverified: { type: "mobile", verification_mode: VerificationMode.UNVERIFIED },
  phone_oidc_discovery: { type: "mobile", verification_mode: VerificationMode.DISCOVERY },
};

const getAddressMapping = (type: any, verification_mode: string | undefined) => {
  if (type === "email") {
    return `addresses.email_${verification_mode}`;
  } else if (type === "mobile") {
    return `addresses.phone_${verification_mode}`;
  }
};

const getGrantTenantRole = (roles?: TenantRoles) => {
  if (roles?.admin) {
    return GrantTenantRoleRequestRoleEnum.Admin;
  }

  if (roles?.business_admin) {
    return GrantTenantRoleRequestRoleEnum.BusinessAdmin;
  }

  if (roles?.auditor) {
    return GrantTenantRoleRequestRoleEnum.Auditor;
  }

  if (roles?.member) {
    return GrantTenantRoleRequestRoleEnum.Member;
  }

  return undefined;
};

const getRevokeTenantRole = (roles?: TenantRoles) => {
  if (roles?.admin) {
    return RevokeTenantRoleRequestRoleEnum.Admin;
  }

  if (roles?.business_admin) {
    return RevokeTenantRoleRequestRoleEnum.BusinessAdmin;
  }

  if (roles?.auditor) {
    return RevokeTenantRoleRequestRoleEnum.Auditor;
  }

  if (roles?.member) {
    return RevokeTenantRoleRequestRoleEnum.Member;
  }

  return undefined;
};

export const getInitialData = (
  jit: JITSettings | undefined,
  attributes: Attribute[],
  serverPool: PoolResponse | undefined,
  idpTenantRole?: string
) => {
  const mappingAddresses = (jit?.provisioning?.user?.addresses ?? []).map(
    ({ source, type, verification_mode }) => ({
      allow_weak_decoding: false,
      source,
      target: getAddressMapping(type, verification_mode),
      type: "string",
      update_on_sign_in: false,
    })
  );
  const mappingIdentifiers = (jit?.provisioning?.user?.identifiers ?? [])
    .slice(1)
    .map(({ source, type }) => ({
      allow_weak_decoding: false,
      source,
      target: `identifiers.${type}`,
      type: "string",
      update_on_sign_in: false,
    }));

  const emailAttribute = attributes.find(attribute => attribute.name === "email");

  return {
    ...jit?.provisioning,
    provisioning: jit?.enabled ? "enabled" : "disabled",
    pool: serverPool ? { id: serverPool.id ?? "", name: serverPool.name ?? "" } : null,
    admin_role_type: jit?.provisioning?.admin_role_type || JITProvisioningAdminRoleTypeEnum.Member,
    user: {
      identifier: jit?.provisioning?.user?.identifiers?.[0] ?? {
        source: emailAttribute?.name ?? "",
        type: "email",
      },
      attributes_mapping: [
        ...(jit?.provisioning?.user?.attributes_mapping ?? []),
        ...mappingAddresses,
        ...mappingIdentifiers,
      ],
    },
    idp_tenant_role: idpTenantRole || "",
  };
};

export interface IdentitiesDetailsProvisioningProps {
  idp: IdpUiModelType;
}

export default function IdentitiesDetailsProvisioning({ idp }: IdentitiesDetailsProvisioningProps) {
  const poolId = idp.jit?.provisioning?.pool_id ?? "";

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(idp.authorization_server_id);
  const noManagePermission = !checkWorkspacePermissionsQuery.data?.manage_idps;

  const listTenantRolesQuery = useListTenantRoles();
  const tenantRoleSubject = (listTenantRolesQuery.data?.subjects || []).find(
    s =>
      s.type === TenantRoleSubjectTypeEnum.Idp &&
      s.idp_id === idp.id &&
      s.tenant_id === idp.tenant_id &&
      s.workspace_id === idp.authorization_server_id
  );

  const getPoolQuery = useGetPool(poolId, { enabled: !!poolId });
  const serverPool = getPoolQuery.data;

  const serverQuery = useGetAuthorizationServer(getTenantId(), idp.authorization_server_id);
  const attributes = useMemo(
    () => serverQuery.data?.authentication_context_settings?.attributes || [],
    [serverQuery.data]
  );

  const initialData = useMemo(() => {
    return getInitialData(
      idp.jit,
      attributes,
      serverPool,
      getGrantTenantRole(tenantRoleSubject?.roles)
    );
  }, [idp, serverPool, attributes, tenantRoleSubject]);

  const grantTenantRoleMutation = useGrantTenantRoleMutation();
  const revokeTenantRoleMutation = useRevokeTenantRoleMutation();

  const updateIDPMutation = useUpdateIDPMutation(idp.id!, idp.authorization_server_id!);

  const form = useForm({
    id: "identities-details-provisioning",
    initialValues: initialData,
    noManagePermission,
    progress:
      serverQuery.isLoading ||
      getPoolQuery.isLoading ||
      listTenantRolesQuery.isLoading ||
      grantTenantRoleMutation.isPending ||
      revokeTenantRoleMutation.isPending ||
      updateIDPMutation.isPending,
  });

  const handleUpdateIDP = body => {
    return updateIDPMutation.mutateAsync({
      aid: idp.authorization_server_id!,
      type: idp.method!,
      iid: idp.id!,
      body: transformIdpToAPI(body),
    });
  };

  const handleUpdate = (provisioning: "enabled" | "disabled", data: FieldValues) => {
    const grantTenantRoleIfNeeded = () => {
      if (idp.authorization_server_id === "admin" && data.idp_tenant_role) {
        return grantTenantRoleMutation.mutateAsync({
          request: {
            role: data.idp_tenant_role,
            idp_id: idp.id,
            tenant_id: idp.tenant_id,
            type: GrantTenantRoleRequestTypeEnum.Idp,
            workspace_id: idp.authorization_server_id,
          },
        });
      }

      return Promise.resolve({});
    };

    const revokeTenantRoleIfNeeded = () => {
      if (
        idp.authorization_server_id === "admin" &&
        getRevokeTenantRole(tenantRoleSubject?.roles)
      ) {
        return revokeTenantRoleMutation.mutateAsync({
          request: {
            role: getRevokeTenantRole(tenantRoleSubject?.roles),
            idp_id: idp.id,
            tenant_id: idp.tenant_id,
            type: RevokeTenantRoleRequestTypeEnum.Idp,
            workspace_id: idp.authorization_server_id,
          },
        });
      }

      return Promise.resolve({});
    };
    if (provisioning === "disabled") {
      return handleUpdateIDP({
        ...idp,
        jit: {
          ...idp.jit,
          enabled: false,
        },
      })
        .then(grantTenantRoleIfNeeded)
        .then(revokeTenantRoleIfNeeded)
        .then(() => notifySuccess("Identity provider updated successfully"))
        .catch(notifyErrorOrDefaultTo("Error occurred while trying to update identity provider"));
    }

    let addresses: { source: string; target: string }[] = [];
    let identifiers: { source: string; target: string }[] = [];

    const user = data.user
      ? {
          ...data.user,
          attributes_mapping: (data.user.attributes_mapping ?? []).filter(mapping => {
            const isAddress = mapping.target.startsWith("addresses.");
            const isIdentifier = mapping.target.startsWith("identifiers.");
            if (isAddress) {
              addresses.push(mapping);
            }
            if (isIdentifier) {
              identifiers.push(mapping);
            }
            return !isAddress && !isIdentifier;
          }),
        }
      : "";

    return handleUpdateIDP({
      ...idp,
      jit: {
        enabled: provisioning === "enabled",
        provisioning: {
          ...idp.jit?.provisioning,
          mode: "auto",
          pool_id: data?.pool?.id ?? "",
          ...(idp.authorization_server_id === "admin"
            ? { admin_role_type: data?.admin_role_type }
            : {}),
          user: {
            ...omit(user, "identifier"),
            addresses: addresses.map(({ source, target }) => ({
              source,
              ...addressesTypeMapper[target.replace("addresses.", "")],
            })),
            identifiers: [
              data.user.identifier,
              ...identifiers.map(({ source, target }) => ({
                source,
                type: target.replace("identifiers.", ""),
              })),
            ],
          },
        },
      },
    })
      .then(revokeTenantRoleIfNeeded)
      .then(() => notifySuccess("Identity provider updated successfully"))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update identity provider"));
  };

  if (serverQuery.isLoading || getPoolQuery.isLoading) {
    return <Progress />;
  }

  return (
    <Form form={form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardRadioGroupField
            id="identities-discovery"
            name="provisioning"
            cards={[
              {
                title: "Disabled",
                description: "Users that authenticate with this provider are not persisted",
                value: "disabled",
                img: iconDisabled,
              },
              {
                title: "Just-in-Time Provisioning",
                description:
                  "Users that authenticate with this provider are persisted in the user store",
                value: "enabled",
                img: iconEnabled,
              },
            ]}
            afterChange={value => {
              if (
                value === "disabled" &&
                initialData.provisioning === "enabled" &&
                idp.authorization_server_id !== "admin"
              ) {
                handleUpdate(value, initialData);
              }
            }}
          />
        </Grid>

        <IdentitiesDetailsProvisioningConfig
          method={idp}
          attributes={attributes}
          noManagePermission={noManagePermission}
          handleUpdate={handleUpdate}
        />
      </Grid>

      <RouteLeavingGuard exact />
    </Form>
  );
}
