import React, { useMemo, useState } from "react";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import { Attribute } from "@cloudentity/acp-admin";

import Alert from "../../../common/components/Alert";
import Dialog from "../../../common/components/Dialog";
import FormInputLabel from "../../../common/components/FormInputLabel";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import SelectField from "../../../common/utils/forms/SelectField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { useFeature } from "../../../common/utils/hooks/useFeature";
import { attributesDataTypeOptions } from "../authnContext/attrbutesDataTypeOptions";
import { attrSourceAndDisplayNameToValue } from "./identities.utils";
import { usePoolSchemasProperties } from "./useProvisioningMappingProperties";

const accessToken = {
  value: "access_token",
  label: "Access token",
};

const idToken = {
  value: "id_token",
  label: "ID token",
};

const userInfo = {
  value: "user_data",
  label: "User info",
};

const custom = {
  value: "root",
  label: "Custom",
};

const jit = {
  value: "jit",
  label: "JIT",
};

function getIdpFields(type: string) {
  switch (type) {
    case "azure":
    case "azureb2c":
      return {
        source: [accessToken, idToken, { value: "user_data", label: "Azure Graph" }],
        variableName: "Claim name",
      };
    case "saml":
      return {
        source: [{ value: "user_data", label: "SAML assertion attribute" }],
        variableName: "Attribute name",
      };
    case "saml_v2":
      return {
        source: [
          { value: "saml_attributes", label: "SAML assertion attribute" },
          { value: SAML_NAME_ID_ID, label: SAML_NAME_ID_LABEL },
        ],
        variableName: "Attribute name",
      };
    case "oidc":
    case "cognito":
    case "auth0":
    case "okta":
      return { source: [accessToken, idToken, userInfo], variableName: "Claim name" };
    case "custom":
    case "static":
    case "external":
      return { source: [], variableName: "Attribute name" };
    case "github":
    case "github_embedded":
      return {
        source: [
          accessToken,
          idToken,
          { value: "user_data", label: "Github Authenticated User Data" },
        ],
        variableName: "Claim name",
      };
    case "intelli_trust":
      return { source: [userInfo], variableName: "Claim name" };
    case "google":
    case "google_embedded":
      return { source: [idToken, userInfo], variableName: "Claim name" };
    default:
      return { source: [accessToken, idToken, userInfo, custom], variableName: "Variable name" };
  }
}

export const SAML_NAME_ID_ID = "saml_name_id";
export const SAML_NAME_ID_LABEL = "SAML Name ID";

export interface IdentitiesDetailsAttributesAddProps {
  existingAttrs: string[];
  methodType: string;
  isJitEnabled: boolean;
  jitPoolId: string | undefined;
  progress: boolean;
  onCreate: (attribute: Attribute) => void;
  onCancel: () => void;
}

const IdentitiesDetailsAttributesAdd = ({
  existingAttrs = [],
  methodType,
  isJitEnabled,
  jitPoolId,
  progress,
  onCreate,
  onCancel,
}: IdentitiesDetailsAttributesAddProps) => {
  const isJitMapUserPoolAttributesToAuthnCtxEnabled = useFeature(
    "jit_map_user_pool_attributes_to_authn_ctx"
  );
  const form = useForm({ id: "identities-add-attribute", progress });

  const fields = useMemo(() => {
    const fields = getIdpFields(methodType);

    if (isJitMapUserPoolAttributesToAuthnCtxEnabled && isJitEnabled) {
      fields.source = [...fields.source, jit];
    }

    return fields;
  }, [isJitEnabled, isJitMapUserPoolAttributesToAuthnCtxEnabled, methodType]);

  const [model, setModel] = useState<{ source: string | null }>({
    source: fields.source.length ? fields.source[0].value : "root",
  });

  const { properties } = usePoolSchemasProperties({ poolId: jitPoolId });

  const samlNameAttributeAlreadyExists =
    model.source === SAML_NAME_ID_ID &&
    !!existingAttrs.find(attr => attr === `root.${SAML_NAME_ID_ID}`);

  return (
    <Dialog onClose={onCancel} id="add-attribute-dialog" title="Add attribute">
      <Form form={form}>
        {fields && fields.source.length > 0 ? (
          <FormControl component="fieldset" style={{ marginBottom: 32 }}>
            <FormInputLabel id="source-label" forId="source" label="Source" />
            <RadioGroup
              aria-label="source"
              name="source"
              value={model.source}
              onChange={(_, value) => {
                setModel({ ...model, source: value });
                form.clearErrors("name");

                if (value === SAML_NAME_ID_ID) {
                  form.setValue("name", SAML_NAME_ID_ID);
                  form.setValue("description", "nameID");
                  form.setValue("type", "string");
                }
              }}
              style={{ flexDirection: "row" }}
            >
              {fields.source.map(({ value, label }) => (
                <FormControlLabel
                  id={`${value}-source`}
                  data-testid={`${value}-source`}
                  value={value}
                  control={<Radio color="primary" />}
                  label={label}
                  key={value}
                />
              ))}
            </RadioGroup>
          </FormControl>
        ) : null}

        {samlNameAttributeAlreadyExists && (
          <Alert severity="info">{SAML_NAME_ID_LABEL} attribute already exists</Alert>
        )}

        {model.source !== SAML_NAME_ID_ID && (
          <>
            {model.source !== "jit" && (
              <TextFieldRequired
                name="name"
                label={fields?.variableName || "Variable name"}
                rules={{
                  validate: {
                    notUniq: v =>
                      existingAttrs.indexOf(`${model.source}.${v}`) === -1 ||
                      "Attribute with given name already exists",
                  },
                }}
              />
            )}
            {model.source === "jit" && (
              <AutocompleteField
                name="name"
                label={fields?.variableName || "Variable name"}
                options={properties.map(o => o.name)}
                onChange={(_, v) => {
                  form.setValue("name", v, { shouldValidate: true });
                  form.setValue("description", properties.find(o => o.name === v)?.description);
                  form.setValue("type", properties.find(o => o.name === v)?.type);
                }}
                getOptionLabel={option => properties.find(o => o.name === option)?.name || option}
                disableClearable
                filterSelectedOptions={false}
                optional={false}
                rules={{
                  required: `${fields?.variableName || "Variable name"} is required`,
                }}
              />
            )}

            <TextFieldRequired name="description" label="Display name" />

            <SelectField
              name="type"
              label="Data type"
              options={attributesDataTypeOptions}
              disabled={model.source === "jit"}
              rules={{
                required: "Data type is required",
              }}
            />
          </>
        )}

        <FormFooter
          disabled={samlNameAttributeAlreadyExists}
          onCancel={onCancel}
          onSubmit={(data: any) => {
            if (model.source === SAML_NAME_ID_ID) {
              onCreate({
                ...data,
                name: SAML_NAME_ID_ID,
              });
            } else {
              onCreate({
                ...data,
                name: attrSourceAndDisplayNameToValue(model.source || "", data.name),
              });
            }
          }}
        />
      </Form>
    </Dialog>
  );
};

export default IdentitiesDetailsAttributesAdd;
