import React, { useEffect, useMemo } from "react";
import { Controller, useWatch } from "react-hook-form";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import omit from "lodash/omit";
import { lensPath, map, mergeDeepRight, over, pathOr, prop, replace, set } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import CardRadioGroup from "../../../common/components/CardRadioGroup";
import FormAccordion from "../../../common/components/FormAccordion";
import FormInputLabel from "../../../common/components/FormInputLabel";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import {
  SamlConfigManualIcon,
  SamlConfigMetadataBasedIcon,
  SamlConfigUploadFileIcon,
  SamlConfigUploadUrlIcon,
  SamlIdentifierSourceAttributeIcon,
  SamlIdentifierSourceSubjectIcon,
} from "../../../common/components/icons/IdpSamlIcons";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import SelectField from "../../../common/utils/forms/SelectField";
import TextField from "../../../common/utils/forms/TextField";
import TextFieldReadOnly from "../../../common/utils/forms/TextFieldReadOnly";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import XMLEditorField from "../../../common/utils/forms/XMLEditorField";
import { validators } from "../../../common/utils/forms/validation";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelSamlType, providers } from "./identities.utils";
import { getUUID, subjectNameIdFormatOptions } from "./saml.utils";

const componentId = "identities-configuration-saml";

interface Props {
  provider: IdpUiModelSamlType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  onLogoEdit?: (data: any) => void;
  customSubmit?: boolean;
  errors: any;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelSamlType) => Promise<any>;
  onResetErrors: (err: any | null) => void;
  onDelete?: (idp: IdpUiModelSamlType) => void;
}

export default function IdentitiesConfigurationSaml({
  provider,
  server,
  updateProgress,
  inEdit,
  errors,
  onLogoEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onResetErrors,
  onInit,
  onDelete,
}: Props) {
  const id = useMemo(() => provider.id || getUUID(), [provider.id]);

  const data = useMemo(
    () => ({
      ...provider,
      id,
      delivery_mode: inEdit
        ? provider.settings?.metadata_url !== ""
          ? ("url" as const)
          : provider.settings?.metadata_xml !== ""
          ? ("xml" as const)
          : ("url" as const)
        : ("url" as const),
      saml_sp_metadata: server?.issuer_url + "/saml/sp/" + id + "/metadata",
      configuration_mode: inEdit
        ? provider.settings?.metadata_xml !== "" || provider.settings?.metadata_url !== ""
          ? "metadata"
          : "manual"
        : "metadata",
    }),
    [provider, server, inEdit, id]
  );

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id: componentId,
    initialValues: data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const identifierSource = form.watch("settings.identifier_source") || "subject";
  const configurationMode =
    useWatch({
      name: "configuration_mode",
      control: form.control,
    }) || "metadata";

  const submitFn: any = (provider: IdpUiModelSamlType, newData: typeof data) => {
    const omitFields = omit(newData, ["delivery_mode", "saml_sp_metadata"]);

    if (configurationMode === "manual") {
      const withIdentifierSource = set(
        lensPath(["settings", "identifier_source"]),
        identifierSource,
        omitFields
      );
      const withParsedCert = over(
        lensPath(["credentials", "idp_certificate"]),
        replace(/\\n|\\r/g, "\n"),
        withIdentifierSource
      );
      const withMetadataURL = set(lensPath(["settings", "metadata_url"]), "", withParsedCert);
      const withMetadataXML = set(lensPath(["settings", "metadata_xml"]), "", withMetadataURL);

      const withAMRResponse = { ...withMetadataXML, ...amrToResponse(omitFields) };

      return mergeDeepRight(provider, withAMRResponse);
    }

    if (configurationMode === "metadata") {
      if (newData.delivery_mode === "xml") {
        const withMetadataXML = set(lensPath(["settings", "metadata_url"]), "", omitFields);
        return mergeDeepRight(provider, withMetadataXML);
      }
      return mergeDeepRight(provider, omitFields);
    }
  };

  const providerMapData = providers.find(p => p.method === "saml");

  const handleSubmitError = err => {
    if (err?.response?.data?.error === "failed to parse metadata xml") {
      form.setError(
        "settings.metadata_xml",
        {
          message: "Failed to parse Metadata XML",
        },
        { shouldFocus: true }
      );
    }
    if (
      err?.response?.data?.error === "failed to call idp metadata url" ||
      err?.response?.data?.error === "failed to read idp metadata url response" ||
      err?.response?.data?.error === "failed to fetch idp metadata"
    ) {
      form.setError(
        "settings.metadata_url",
        {
          message: "Failed to fetch IDP Metadata",
        },
        { shouldFocus: true }
      );
    }
  };

  useEffect(() => {
    onInit &&
      onInit(() =>
        form.handleSubmit(
          data => onSubmit && onSubmit(submitFn(provider, data)).catch(handleSubmitError)
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  const deliveryMode: "url" | "xml" =
    useWatch({
      name: "delivery_mode",
      control: form.control,
    }) || "url";

  useEffect(() => {
    form.register("id");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Form form={form}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <CommonIdpConfigUpper
              provider={provider}
              inEdit={inEdit}
              onLogoEdit={onLogoEdit}
              onUpdate={onSubmit}
            />

            {(!inEdit || (inEdit && (provider.version ?? 0) >= 3)) && (
              <TextFieldReadOnly
                name="saml_sp_metadata"
                label="Entity ID"
                helperText="Entity ID is also URL for SAML SP Metadata"
                withCopy
                withLink
              />
            )}

            {inEdit && (provider.version ?? 100) < 3 && (
              <TextField
                name="settings.entity_issuer"
                label="Entity ID"
                defaultValue={pathOr("", ["settings", "entity_issuer"], provider)}
                optional={false}
              />
            )}

            <TextFieldReadOnly name="redirectUrl" label="ACS URL" withCopy />

            <div style={{ marginBottom: 32 }}>
              <Controller
                name="configuration_mode"
                control={form.control}
                render={() => (
                  <CardRadioGroup
                    value={configurationMode}
                    disabled={!checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit}
                    onChange={v => form.setValue("configuration_mode", v)}
                    id="configuration-mode"
                    cardStyle={{ padding: 6 }}
                    cards={[
                      {
                        title: "Metadata-based Configuration",
                        value: "metadata",
                        imgComponent: SamlConfigMetadataBasedIcon,
                      },
                      {
                        title: "Manual Configuration",
                        value: "manual",
                        imgComponent: SamlConfigManualIcon,
                      },
                    ]}
                  />
                )}
              />
            </div>

            {configurationMode === "metadata" && (
              <>
                <div style={{ marginBottom: 24 }}>
                  <FormInputLabel id="configuration-mode-label" label="Metadata delivery mode" />
                  <Controller
                    name="delivery_mode"
                    control={form.control}
                    render={() => (
                      <CardRadioGroup
                        value={deliveryMode}
                        disabled={
                          !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit
                        }
                        onChange={v => form.setValue("delivery_mode", v)}
                        id="configuration-mode"
                        cardStyle={{ padding: 6 }}
                        cards={[
                          {
                            title: "Fetch from URL",
                            value: "url",
                            imgComponent: SamlConfigUploadUrlIcon,
                          },
                          {
                            title: "File or RAW XML",
                            value: "xml",
                            imgComponent: SamlConfigUploadFileIcon,
                          },
                        ]}
                      />
                    )}
                  />
                </div>
                {deliveryMode === "url" && (
                  <TextFieldRequired
                    name="settings.metadata_url"
                    label="Metadata URL"
                    defaultValue={pathOr("", ["settings", "metadata_url"], provider)}
                    rules={{
                      validate: {
                        validURL: validators.validURL({ label: "Metadata URL" }),
                      },
                    }}
                  />
                )}
                {deliveryMode === "xml" && (
                  <XMLEditorField
                    name="settings.metadata_xml"
                    editorLabel="IDP Metadata"
                    rules={{
                      required: "Metadata XML is required",
                    }}
                    onChange={() => form.clearErrors("settings.metadata_xml")}
                    withUpload
                  />
                )}
              </>
            )}
            {configurationMode === "manual" && (
              <>
                <TextFieldRequired
                  name="settings.sso_url"
                  label="Sign-in URL"
                  defaultValue={pathOr("", ["settings", "sso_url"], provider)}
                  rules={{
                    validate: {
                      validURL: validators.validURL({ label: "Sign-in URL" }),
                    },
                  }}
                />
                <TextFieldRequired
                  name="credentials.idp_certificate"
                  label="IDP certificate"
                  rules={{
                    validate: {
                      maxLength: () => true,
                      validCert: validators.validCertificate({ label: "IDP certificate" }),
                    },
                  }}
                  defaultValue={pathOr("", ["credentials", "idp_certificate"], provider)}
                  multiline
                  minRows={5}
                  maxRows={5}
                  placeholder={"-----BEGIN CERTIFICATE-----\n-----END CERTIFICATE-----"}
                  externalErrors={
                    errors &&
                    errors.status_code === 422 &&
                    errors.error === "certificate is not valid"
                      ? "IDP certificate is not valid"
                      : null
                  }
                  onChange={() => onResetErrors(null)}
                />

                <div style={{ marginBottom: 32 }}>
                  <FormInputLabel id="identifier-source" label="Identifier source" />
                  <Controller
                    name="settings.identifier_source"
                    control={form.control}
                    render={() => (
                      <CardRadioGroup
                        value={identifierSource}
                        disabled={
                          !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit
                        }
                        onChange={v => form.setValue("settings.identifier_source", v)}
                        id="identifier-source"
                        cardStyle={{ padding: 6 }}
                        cards={[
                          {
                            title: "Subject",
                            value: "subject",
                            imgComponent: SamlIdentifierSourceSubjectIcon,
                          },
                          {
                            title: "Attribute",
                            value: "attribute",
                            imgComponent: SamlIdentifierSourceAttributeIcon,
                          },
                        ]}
                      />
                    )}
                  />
                </div>

                {identifierSource === "attribute" && (
                  <TextFieldRequired
                    name="settings.identifier_attribute"
                    label="Identifier attribute"
                    defaultValue={pathOr("", ["settings", "identifier_attribute"], provider)}
                  />
                )}

                <div
                  style={{
                    display: identifierSource === "subject" ? "block" : "none",
                    width: "100%",
                  }}
                >
                  <SelectField
                    name="settings.subject_name_id_format"
                    label="Name ID format"
                    options={subjectNameIdFormatOptions}
                  />
                </div>
                <CommonIdpConfig data={provider} />
              </>
            )}

            {inEdit && (
              <FormAccordion title="Advanced settings" id={componentId}>
                <AutocompleteField
                  name="static_amr"
                  label="Authentication Method Reference"
                  helperText="If set overwrites AMR obtained from this authentication method"
                  defaultValue={pathOr([], ["static_amr"], provider)}
                  options={map(prop("value"), amrOptions)}
                  getOptionLabel={getAMRLabel}
                  multiple
                />
                <CheckboxField
                  name="settings.skip_in_response_to_verification"
                  label="Skip inResponseTo verification"
                  withConfirmation
                  confirmation={{
                    title: "Skip inResponseTo verification",
                    content: (
                      <div style={{ fontSize: 15 }}>
                        Not validating the <b>InResponseTo</b> parameter results in a lower security
                        level of your system as the login is treated as if it was IDP-initiated.{" "}
                        <br />
                        <br />
                        You may need to turn this flag on when the <b>InResponseTo</b> parameters is
                        not returned by the IDP.
                      </div>
                    ),
                    confirmText: "Skip",
                  }}
                />
                {serverSSOEnabled && <SSOIDPSettings />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={provider}
              onSubmit={data =>
                onSubmit && onSubmit(submitFn(provider, data)).catch(handleSubmitError)
              }
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </Form>
  );
}
