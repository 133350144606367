import React, { useEffect, useState } from "react";

import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import isString from "lodash/isString";
import { makeStyles } from "tss-react/mui";

import {
  GrantWorkspaceRoleRequestRoleEnum,
  ServerResponseTypeEnum,
  WorkspacePermissionsResponse,
  WorkspaceRoles,
  WorkspaceRoleSubject,
} from "@cloudentity/acp-admin";
import { BaseUserWithData, PoolPermissionsResponse, UserWithData } from "@cloudentity/acp-identity";

import { portalMode } from "../../../../common/api/paths";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import { useFormContext } from "../../../../common/utils/forms/Form";
import {
  useCheckPoolPermissions,
  useListWorkspacePools,
} from "../../../services/adminIdentityPoolsQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import { useGetWorkspace } from "../../../services/adminServersQuery";
import Tooltip from "../../common/Tooltip";
import { workspaceRoleToDisplayRole } from "../identityPools/identityPool/users/list/utils";
import { usePoolRootUrl } from "../identityPools/utils";
import { useIsCurrentLoggedInUser } from "./useIsCurrentLoggedInUser";

const useStyles = makeStyles()(() => ({
  option: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start !important",
  },
  optionDescription: {
    color: "gray",
    marginTop: 4,
    lineHeight: "16px",
  },
  optionName: {
    fontWeight: 500,
    fontSize: 14,
  },
}));

interface Props {
  user?: UserWithData | BaseUserWithData;
  wid: string;
  workspaceSubjects?: WorkspaceRoleSubject[];
  poolId?: string;
  multiple?: boolean;
  label?: string;
  roleOptions?: { value: string; name: string; description: string }[];
  optional?: boolean;
  disabled?: boolean;
  isInviteWorkspaceAdministrator?: boolean;
}

export default function WorkspaceRoleSelectField({
  user,
  wid,
  workspaceSubjects = [],
  poolId,
  multiple = true,
  label,
  roleOptions,
  optional,
  disabled,
  isInviteWorkspaceAdministrator,
}: Props) {
  const { cx, classes } = useStyles();
  const { form } = useFormContext();
  const checkWorkspacePermissions = useCheckWorkspacePermissions(wid);
  const checkPoolPermissions = useCheckPoolPermissions(user?.user_pool_id || poolId || "");
  const getWorkspaceQuery = useGetWorkspace(wid);
  const workspace = getWorkspaceQuery.data;

  const { mode } = usePoolRootUrl();

  const isOrganization = workspace?.type === ServerResponseTypeEnum.Organization;

  const listWorkspacePoolsQuery = useListWorkspacePools(
    { wid },
    {
      enabled:
        isOrganization &&
        mode === "workspace" &&
        !!checkWorkspacePermissions.data?.list_identity_pools,
    }
  );

  const isCurrentLoggedInUser = useIsCurrentLoggedInUser(user, wid);

  const workspaceSubject = workspaceSubjects.find(ws => ws.identity_pool_user_id === user?.id);

  const hasMultiplePools = (listWorkspacePoolsQuery.data?.pools?.length ?? 0) > 1;

  const [initiallyHasTeamManagerAssigned, setInitiallyHasTeamManagerAssigned] = useState(false);
  useEffect(() => {
    const roles = form.getValues("roles");
    if ((roles || []).includes("team_manager")) {
      setInitiallyHasTeamManagerAssigned(true);
    }
  }, [form]);

  const options =
    roleOptions ||
    getWorkspaceRoleOptions(isOrganization, hasMultiplePools || initiallyHasTeamManagerAssigned);

  const isDisabled =
    isCurrentLoggedInUser ||
    !isAnyWorkspaceOrPoolRoleAllowed(checkWorkspacePermissions.data, checkPoolPermissions.data) ||
    !!disabled;

  return checkWorkspacePermissions.data?.read_roles &&
    (isOrganization || workspace?.id === "admin" || isInviteWorkspaceAdministrator) ? (
    <AutocompleteField
      name="roles"
      label={
        label ?? (isOrganization ? "Roles" : portalMode === "b2b" ? "Roles" : "Workspace roles")
      }
      options={
        isDisabled
          ? options.map(o => o.value)
          : getWorkspaceRoleOptionsByPermissions(
              options,
              checkWorkspacePermissions.data,
              checkPoolPermissions.data
            ).map(o => o.value)
      }
      getOptionLabel={option => {
        return options.find(o => o.value === option)?.name || option;
      }}
      renderOption={({ key, ...props }, option) => {
        const workspaceOption = options.find(o => o.value === option);
        return (
          <li key={key} {...props} className={cx(props.className, classes.option)}>
            <div className={classes.optionName}>{workspaceOption?.name ?? option}</div>
            <Typography variant="textSM" className={classes.optionDescription}>
              {workspaceOption?.description}
            </Typography>
          </li>
        );
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const allOptions = roleOptions || getWorkspaceRoleOptions(isOrganization, true);
          const workspaceOption = allOptions.find(o => o.value === option);

          let label = workspaceOption?.name || option;
          const description = workspaceOption?.description ?? "";

          return (
            <Tooltip title={description} key={option}>
              <Chip
                label={label}
                {...getTagProps({ index })}
                disabled={
                  isDisabled ||
                  (isCurrentLoggedInUser
                    ? getHighestWorkspaceRole(workspaceSubject?.roles) === option
                    : false)
                }
              />
            </Tooltip>
          );
        })
      }
      onChange={(_, value = []) => {
        const highestWorkspaceRole = getHighestWorkspaceRole(workspaceSubject?.roles);
        const currentLoggedInUserRequiredRole =
          isCurrentLoggedInUser &&
          highestWorkspaceRole !== "" &&
          !(value || []).includes(highestWorkspaceRole)
            ? [highestWorkspaceRole]
            : [];

        form.clearErrors("roles");
        form.setValue(
          "roles",
          isCurrentLoggedInUser
            ? [
                ...currentLoggedInUserRequiredRole,
                ...(Array.isArray(value) ? value : isString(value) ? [value] : []),
              ]
            : value
        );
      }}
      filterOptions={(filterOptions: string[], state: any) => {
        return filterOptions.filter(option => {
          const workspaceOption = options.find(o => o.value === option);
          const label = workspaceOption?.name ?? option;
          return label.toLowerCase().includes(state.inputValue?.toLowerCase());
        });
      }}
      disabled={isDisabled}
      optional={!!optional}
      multiple={multiple}
      filterSelectedOptions={false}
      blurOnSelect
      clearIcon={false}
      rules={!optional ? { required: "Roles are required" } : {}}
    />
  ) : null;
}

export const getWorkspaceRoleOptions = (isOrganization: boolean, hasMultiplePools: boolean) => [
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Admin,
    name: workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Admin, isOrganization),
    description:
      workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Admin, isOrganization) +
      " has the power to manage organization settings, populations and users",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Auditor,
    name: workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Auditor, isOrganization),
    description:
      workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Auditor, isOrganization) +
      " has the power to view organization settings, populations and users",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.Manager,
    name: workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Manager, isOrganization),
    description:
      workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.Manager, isOrganization) +
      " has the power to update the organization's metadata and oversee users associated with the managed populations.",
  },
  {
    value: GrantWorkspaceRoleRequestRoleEnum.UserManager,
    name: workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.UserManager, isOrganization),
    description:
      workspaceRoleToDisplayRole(GrantWorkspaceRoleRequestRoleEnum.UserManager, isOrganization) +
      " has the power to manage users stored in user populations connected to the organization.",
  },
  ...(hasMultiplePools
    ? [
        {
          value: "team_manager",
          name: "User Population Manager",
          description:
            "User Population Manager has the power to manage users stored in the assigned user population.",
        },
      ]
    : []),
];

const getWorkspaceRoleOptionsByPermissions = (
  allOptions: { value: string; name: string; description: string }[],
  workspacePermissions: WorkspacePermissionsResponse | undefined,
  poolPermissions: PoolPermissionsResponse | undefined
) => {
  return allOptions.filter(o => {
    if (o.value === GrantWorkspaceRoleRequestRoleEnum.Admin) {
      return !!workspacePermissions?.manage_admin_role;
    }
    if (o.value === GrantWorkspaceRoleRequestRoleEnum.Auditor) {
      return !!workspacePermissions?.manage_auditor_role;
    }
    if (o.value === GrantWorkspaceRoleRequestRoleEnum.Manager) {
      return !!workspacePermissions?.manage_manager_role;
    }
    if (o.value === GrantWorkspaceRoleRequestRoleEnum.UserManager) {
      return !!workspacePermissions?.manage_user_manager_role;
    }
    if (o.value === "team_manager") {
      return poolPermissions?.manage_user_manager_role;
    }

    return true;
  });
};

const getHighestWorkspaceRole = (roles?: WorkspaceRoles): string => {
  if (!roles) {
    return "";
  }

  if (roles[GrantWorkspaceRoleRequestRoleEnum.Admin]) {
    return GrantWorkspaceRoleRequestRoleEnum.Admin;
  }
  if (roles[GrantWorkspaceRoleRequestRoleEnum.Manager]) {
    return GrantWorkspaceRoleRequestRoleEnum.Manager;
  }
  if (roles[GrantWorkspaceRoleRequestRoleEnum.UserManager]) {
    return GrantWorkspaceRoleRequestRoleEnum.UserManager;
  }
  if (roles[GrantWorkspaceRoleRequestRoleEnum.Auditor]) {
    return GrantWorkspaceRoleRequestRoleEnum.Auditor;
  }
  if (roles[GrantWorkspaceRoleRequestRoleEnum.Member]) {
    return GrantWorkspaceRoleRequestRoleEnum.Member;
  }

  return "";
};

export const isAnyWorkspaceOrPoolRoleAllowed = (
  workspacePermissions?: WorkspacePermissionsResponse,
  poolPermissions?: PoolPermissionsResponse
) =>
  workspacePermissions?.manage_admin_role ||
  workspacePermissions?.manage_auditor_role ||
  workspacePermissions?.manage_manager_role ||
  workspacePermissions?.manage_user_manager_role ||
  poolPermissions?.manage_user_manager_role;
