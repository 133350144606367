import React, { useState } from "react";

import HowToRegOutlined from "@mui/icons-material/HowToRegOutlined";
import WarningIcon from "@mui/icons-material/ReportProblem";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import { Trash } from "react-feather";

import { Attribute, ServerResponse } from "@cloudentity/acp-admin";

import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import EmptyState from "../../../common/components/EmptyState";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import EnhancedTable from "../common/EnhancedTable";
import { useWorkspace } from "../common/useWorkspace";
import { mapDataTypeToDisplayName } from "./attrbutesDataTypeOptions";

const headCells = [
  { id: "variable_name", label: "Variable Name", align: "left" },
  { id: "description", label: "Description", align: "left" },
  { id: "data_type", label: "Data Type", align: "left" },
  { id: "actions", label: "", align: "right" },
];

interface Props {
  attributes: Attribute[];
  progress: boolean;
  onRemove: (name: string) => Promise<ServerResponse>;
  searchText: string;
}

function AuthNContextAttributesList({ attributes, progress, onRemove, searchText }: Props) {
  const [workspace] = useWorkspace();
  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(workspace);

  const [removeConfirmDialog, setRemoveConfirmDialog] = useState<{ name: string } | null>(null);

  return (
    <>
      {progress && (
        <LinearProgress
          style={{ position: "fixed", top: 0, left: 0, width: "100%", zIndex: 1000 }}
        />
      )}
      {!searchText && attributes.length === 0 && (
        <EmptyState
          title="Authentication Context Schema"
          description="Create and define your authentication context schema"
          icon={HowToRegOutlined}
          style={{ marginTop: 80 }}
        />
      )}
      {searchText && attributes.length === 0 && (
        <div style={{ color: "gray" }}>No attributes found</div>
      )}
      {attributes.length > 0 && (
        <EnhancedTable
          id="attributes"
          data={attributes}
          headCells={headCells}
          createRow={(row, i, classes) => (
            <TableRow
              key={i + (row.name || "") + (row.description + "")}
              className={classes.rowWithHoverIcons}
              style={{ height: 57 }}
            >
              <TableCell component="th" scope="row" style={{ wordBreak: "break-word" }}>
                {row.name}
              </TableCell>
              <TableCell style={{ wordBreak: "break-word" }}>{row.description}</TableCell>
              <TableCell>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {mapDataTypeToDisplayName(row.type)}
                  {row.type === "any" && (
                    <Tooltip
                      title="This attribute doesn't have a type. You should remove it and add a typed attribute instead."
                      placement="top-end"
                    >
                      <WarningIcon htmlColor="#ff9800" fontSize="small" style={{ marginLeft: 6 }} />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell align="right" padding="none" style={{ padding: "0 24px" }}>
                {checkWorkspacePermissionsQuery.data?.update_workspace && (
                  <IconButton
                    onClick={e => {
                      e.stopPropagation();
                      setRemoveConfirmDialog({ name: row.name || "" });
                    }}
                    aria-label="delete"
                    className={classes.iconButton}
                    size="large"
                  >
                    <Trash size="14" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          )}
        />
      )}

      {removeConfirmDialog && (
        <ConfirmationDialog
          title="Delete Attribute"
          content={
            <>
              You're about to delete the <b>{removeConfirmDialog.name}</b> attribute. This cannot be
              undone. Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setRemoveConfirmDialog(null)}
          progress={progress}
          onConfirm={() => {
            onRemove(removeConfirmDialog.name).finally(() => setRemoveConfirmDialog(null));
          }}
        />
      )}
    </>
  );
}

export default AuthNContextAttributesList;
