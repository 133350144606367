import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import Paper from "@mui/material/Paper";
import { map, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";
import { PoolResponse } from "@cloudentity/acp-identity";

import FormAccordion from "../../../../common/components/FormAccordion";
import FormInfoBoxWithButton from "../../../../common/components/FormInfoBoxWithButton";
import RouteLeavingGuard from "../../../../common/components/RouteLeavingGuard";
import AutocompleteField from "../../../../common/utils/forms/AutocompleteField";
import CheckboxField from "../../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import { BUILD_IN_ADMIN_POOL_ID } from "../../workspaceDirectory/administrator/AdministratorManagement";
import IdentityPoolForDialog from "../../workspaceDirectory/identityPools/identityPool/IdentityPoolForDialog";
import { usePoolRootUrl } from "../../workspaceDirectory/identityPools/utils";
import { CommonIdpConfig } from "../CommonIdpConfig";
import CommonIdpConfigUpper from "../CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "../IdentitiesDetailsFooter";
import SSOIDPSettings from "../SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "../amrOptions";
import { IdpUiModelType, providers } from "../identities.utils";

const id = "identities-configuration-identity-pool";

interface Props {
  provider: IdpUiModelType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  pools: PoolResponse[];
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelType) => void;
  onDelete?: (idp: IdpUiModelType) => void;
}

function IdentitiesConfigurationIdentityPoolDetail({
  provider,
  server,
  updateProgress,
  onCancel,
  onSubmit,
  inEdit,
  pools,
  onDelete,
}: Props) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { rootUrl } = usePoolRootUrl();
  const [poolDialog, setPoolDialog] = useState<IdpUiModelType | null>(null);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id,
    initialValues: provider as IdpUiModelType & { method: any },
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps,
  });

  const submitFn = (provider, data) => ({
    ...provider,
    ...amrToResponse(data),
    ...data,
  });

  const providerMapData = providers.find(p => p.method === "identity_pool");

  const pool = pools.find(p => p.id === provider.identity_pool_id);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <Form form={form}>
      <Paper style={{ padding: 32 }}>
        {providerMapData && (
          <CardWithIconAndTitle
            img={providerMapData.icon}
            title={providerMapData.name}
            id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
            style={{ marginBottom: 32, width: "50%" }}
            titleStyle={{ fontWeight: 600 }}
          />
        )}

        <CommonIdpConfigUpper provider={provider} onUpdate={onSubmit} inEdit={inEdit} />

        {pool && (
          <FormInfoBoxWithButton
            id="identity-pool"
            label="Identity Pool"
            value={pool.name || provider.identity_pool_id || ""}
            buttonLabel="Manage Pool"
            onClick={() => {
              if (provider.identity_pool_id === BUILD_IN_ADMIN_POOL_ID) {
                setPoolDialog(provider);
              } else {
                navigate(
                  `${pool.workspace_id ? rootUrl : "/identity-pools/pools"}/${
                    provider.identity_pool_id
                  }/signin-and-signup`,
                  {
                    state: { backToWorkspace: pathname },
                  }
                );
              }
            }}
          />
        )}

        <CommonIdpConfig data={provider} />

        <FormAccordion title="Advanced settings" id={id}>
          <AutocompleteField
            name="static_amr"
            label="Authentication Method Reference"
            helperText="If set overwrites AMR obtained from this authentication method"
            defaultValue={pathOr([], ["static_amr"], provider)}
            options={map(prop("value"), amrOptions)}
            getOptionLabel={getAMRLabel}
            multiple
          />
          {serverSSOEnabled && <SSOIDPSettings />}

          <CheckboxField
            name="config.reload_claims_at_refresh_token"
            label="Reload Claims at Refresh Token"
            helperText="If checked, the claims are reloaded while issuing an access token"
          />
        </FormAccordion>

        <IdentitiesDetailsFooter
          idp={provider}
          onSubmit={data => onSubmit && onSubmit(submitFn(provider, data))}
          onCancel={onCancel}
          onDelete={onDelete}
        />

        {poolDialog && (
          <IdentityPoolForDialog
            poolId={poolDialog.identity_pool_id}
            serverId={poolDialog.authorization_server_id}
            onCancel={() => setPoolDialog(null)}
          />
        )}
      </Paper>
      <RouteLeavingGuard />
    </Form>
  );
}

export default IdentitiesConfigurationIdentityPoolDetail;
