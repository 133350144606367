import React, { useEffect, useMemo, useState } from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useQueryClient } from "@tanstack/react-query";
import { path } from "ramda";

import {
  GrantTenantRoleRequestRoleEnum,
  GrantTenantRoleRequestTypeEnum,
  WorkspaceResponse,
} from "@cloudentity/acp-admin";
import {
  NewUserIdentifierTypeEnum,
  NewUserPayloadStatusEnum,
  NewUserVerifiableAddressStatusEnum,
  NewUserVerifiableAddressTypeEnum,
  UserWithData,
} from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../common/api/paths";
import Dialog from "../../../../common/components/Dialog";
import {
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../common/components/notifications/notificationService";
import CheckboxField from "../../../../common/utils/forms/CheckboxField";
import Form, { useForm } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import { useCheckPoolPermissions } from "../../../services/adminIdentityPoolsQuery";
import identityUsersApi from "../../../services/adminIdentityUsersApi";
import { getUserQueryKey, listUsersQueryKey } from "../../../services/adminIdentityUsersQuery";
import { useCheckWorkspacePermissions } from "../../../services/adminPermissionsQuery";
import adminRolesApi from "../../../services/adminRolesApi";
import { listTenantRoles, listUserRoles } from "../../../services/adminRolesQuery";
import { useGetAuthorizationServer } from "../../../services/adminServersQuery";
import { serverTypeToRoleOptions } from "../../manageAccess/workspaceRoleOptions";
import { grantWorkspaceRoles } from "../identityPools/identityPool/users/list/utils";
import AssignedWorkspaceField from "./AssignedWorkspaceField";
import TenantRoleSelectField from "./TenantRoleSelectField";
import WorkspaceRoleSelectField, {
  isAnyWorkspaceOrPoolRoleAllowed,
} from "./WorkspaceRoleSelectField";

interface Props {
  workspaceId: string;
  identityPoolID: string;
  role: GrantTenantRoleRequestRoleEnum;
  onCreated: (user: UserWithData, addAnotherUser: boolean) => void;
  onClose: () => void;
}

export default function AdministratorUserCreate({
  workspaceId,
  identityPoolID,
  role,
  onCreated,
  onClose,
}: Props) {
  const [progress, setProgress] = useState(false);
  const [identifierError, setIdentifierError] = useState("");

  const queryClient = useQueryClient();

  const initialData = useMemo(
    () => ({
      email: "",
      given_name: "",
      family_name: "",
      role,
      roles: null,
      server: null as WorkspaceResponse | null,
    }),
    [role]
  );

  const form = useForm({
    id: "administrator-create-user",
    initialValues: initialData,
    progress,
  });

  const server = form.watch("server");

  const serverQuery = useGetAuthorizationServer(getTenantId(), server?.id, {
    enabled: !!server?.id,
  });
  const checkWorkspacePermissions = useCheckWorkspacePermissions(workspaceId);
  const checkPoolPermissions = useCheckPoolPermissions(identityPoolID);

  const tenantRole = form.watch("role");

  const trigger = form.trigger;
  useEffect(() => {
    trigger("roles");
    trigger("server");
  }, [trigger, tenantRole]);

  const handleCreate = data => {
    setProgress(true);

    const req = {
      ipID: identityPoolID,
      newUser: {
        payload: {
          given_name: data.given_name.trim(),
          family_name: data.family_name.trim(),
        },
        metadata: {},
        status: NewUserPayloadStatusEnum.New,
        credentials: [],
        identifiers: [
          {
            identifier: data.email,
            type: NewUserIdentifierTypeEnum.Email,
          },
        ],
        verifiable_addresses: [
          {
            address: data.email,
            status: NewUserVerifiableAddressStatusEnum.Active,
            type: NewUserVerifiableAddressTypeEnum.Email,
            verified: false,
          },
        ],
      },
    };

    let createdUser: UserWithData;
    return identityUsersApi
      .createUser(req)
      .then(res => {
        createdUser = res.data;
        queryClient.setQueryData(getUserQueryKey(getTenantId(), res.data.id), res.data);
      })
      .then(() =>
        identityUsersApi
          .sendActivationMessage({
            ipID: identityPoolID,
            userID: createdUser.id!,
            serverId: workspaceId,
          })
          .then(() =>
            notifySuccess(
              <span>
                Invite sent to: <strong>{data.email}</strong>
              </span>
            )
          )
          .catch(notifyErrorOrDefaultTo("Error occurred when trying to send activation message"))
      )
      .then(() => {
        if (data.role) {
          return adminRolesApi
            .grantTenantRole({
              request: {
                role: data.role,
                type: GrantTenantRoleRequestTypeEnum.IdentityPoolUser,
                identity_pool_id: createdUser.user_pool_id,
                identity_pool_user_id: createdUser.id,
                tenant_id: getTenantId(),
              },
            })
            .then(() => queryClient.invalidateQueries({ queryKey: listTenantRoles() }))
            .then(() =>
              queryClient.invalidateQueries({
                queryKey: listUserRoles(createdUser.user_pool_id, createdUser.id),
              })
            )
            .catch(notifyErrorOrDefaultTo("Error occurred when trying to grant tenant role"));
        }

        return Promise.resolve();
      })
      .then(() => {
        if ((data.roles || []).length > 0 && createdUser) {
          return grantWorkspaceRoles(
            queryClient,
            data.server?.id || workspaceId,
            createdUser,
            data.roles
          );
        }
        return Promise.resolve();
      })
      .then(() => {
        if (data.addAnotherItem) {
          form.reset({
            ...initialData,
            role: data.role,
            roles: (data.roles || []).length > 0 ? data.roles || [] : initialData.roles,
            server: data.server || initialData.server,
          });
        }

        onCreated(createdUser, data.addAnotherItem);
      })
      .then(() =>
        queryClient.invalidateQueries({
          queryKey: listUsersQueryKey(getTenantId(), identityPoolID),
        })
      )
      .catch(err => {
        if (path(["response", "status"], err) === 409) {
          setIdentifierError("Identifier is already registered");
          return err;
        }

        return notifyErrorOrDefaultTo("Error occurred when trying to create user")(err);
      })
      .finally(() => setProgress(false));
  };

  return (
    <Dialog id="administrator-create-user" onClose={onClose} title="Invite administrator">
      <Form form={form}>
        <TextFieldRequired
          name="email"
          label="Email"
          rules={{
            validate: {
              validEmail: validators.validEmail({ label: "Value" }),
            },
          }}
          externalErrors={identifierError}
          onChange={() => setIdentifierError("")}
          inputProps={{ autoComplete: "off" }}
          autoFocus
        />
        <TextFieldRequired name="given_name" label="First name" />
        <TextFieldRequired name="family_name" label="Last name" />

        <TenantRoleSelectField identityPoolId={identityPoolID} />

        {tenantRole === GrantTenantRoleRequestRoleEnum.Member && (
          <>
            <Divider style={{ marginBottom: 16 }} />
            <Typography style={{ marginBottom: 32 }}>
              Select the workspace or organization that user should be able to manage
            </Typography>
            <AssignedWorkspaceField
              label="Assigned workspace or organization"
              name="server"
              optional={tenantRole !== GrantTenantRoleRequestRoleEnum.Member}
            />

            {isAnyWorkspaceOrPoolRoleAllowed(
              checkWorkspacePermissions.data,
              checkPoolPermissions.data
            ) && (
              <WorkspaceRoleSelectField
                wid={workspaceId}
                poolId={identityPoolID}
                label="Role"
                multiple={false}
                optional={tenantRole !== GrantTenantRoleRequestRoleEnum.Member}
                roleOptions={serverTypeToRoleOptions(serverQuery.data?.type)}
                disabled={!server}
              />
            )}
          </>
        )}

        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <CheckboxField
              name="addAnotherItem"
              label="Invite another user"
              style={{ marginBottom: 0 }}
            />
          </div>
          <div>
            <FormFooter submitText="Invite" onCancel={onClose} onSubmit={handleCreate} />
          </div>
        </div>
      </Form>
    </Dialog>
  );
}
