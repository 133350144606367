import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FieldValues, useFieldArray } from "react-hook-form";

import Checklist from "@mui/icons-material/Checklist";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextFieldMUI from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { AlertTriangle, LogIn, MoreVertical, RefreshCw } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { Attribute } from "@cloudentity/acp-admin";

import identityPoolIcon from "../../../../assets/images/logos/idp/identity-pool.svg";
import saIcon from "../../../../assets/images/logos/sa/sa_rgb_mark_blk.svg";
import BulkActionsToolbar from "../../../../common/components/BulkActionsToolbar";
import Checkbox from "../../../../common/components/Checkbox";
import IconButton from "../../../../common/components/IconButton";
import Progress from "../../../../common/components/Progress";
import Select from "../../../../common/components/Select";
import CheckboxField from "../../../../common/utils/forms/CheckboxField";
import { useFormContext } from "../../../../common/utils/forms/Form";
import FormFooter from "../../../../common/utils/forms/FormFooter";
import TextField from "../../../../common/utils/forms/TextField";
import { useGetPool, useListWorkspacePools } from "../../../services/adminIdentityPoolsQuery";
import CardWithIconAndTitle from "../../common/CardWithIconAndTitle";
import Tooltip from "../../common/Tooltip";
import { IdpUiModelType, providers } from "../identities.utils";
import { useProvisioningMappingProperties } from "../useProvisioningMappingProperties";
import AuthnContextAutocomplete from "./AuthnContextAutocomplete";
import IdentitiesDetailsProvisioningMappingMenu from "./IdentitiesDetailsProvisioningMappingMenu";
import { typeSelectOptions } from "./TypeSelect";

const useStyles = makeStyles()(theme => ({
  header: {
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
  },
  rowDisabled: {
    backgroundColor: "#FCFCFF",
    "& div[role='button'], .MuiInputBase-root": {
      backgroundColor: "#F2F3F4",
    },
  },
  logo: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: 32,
  },
  groupContainer: {
    borderBottom: "solid 1px #e7e7e7",
  },
  group: {
    paddingLeft: 0,
  },
  groupLabel: {
    fontSize: 14,
    lineHeight: "18px",
    padding: "8px 12px",
    color: theme.palette.secondary.light,
    background: "#FBFCFD",
  },
  groupLabelCode: {
    backgroundColor: "transparent",
    fontSize: 11,
    color: theme.palette.secondary.light,
  },
  identifierAutocomplete: {
    "& .MuiInputBase-root": {
      paddingTop: 6,
      paddingBottom: 6,
      minHeight: 48,
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 80px 1fr",

    "& > div": {
      borderBottom: "1px solid #E9EBEC",
      padding: "24px 32px",
    },
  },
  iconCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 !important",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  badge: {
    border: `solid 1px ${theme.palette.warning.main}`,
    backgroundColor: "lightyellow",
    padding: 0,
    color: theme.palette.warning.main,
  },
}));

const getGroupByLabels = (group: string, classes: any) => {
  switch (group) {
    case "addresses":
      return (
        <>
          Addresses <code className={classes.groupLabelCode}>(verifiable_addresses[])</code>
        </>
      );
    case "identifiers":
      return (
        <>
          Identifiers <code className={classes.groupLabelCode}>(identifiers[])</code>
        </>
      );
    case "payload":
      return (
        <>
          Payload <code className={classes.groupLabelCode}>(payload.*)</code>
        </>
      );
    case "metadata":
      return (
        <>
          Metadata <code className={classes.groupLabelCode}>(metadata.*)</code>
        </>
      );
    case "business_metadata":
      return (
        <>
          Business Metadata <code className={classes.groupLabelCode}>(business_metadata.*)</code>
        </>
      );
    default:
      return null;
  }
};

const isModifyOrDeleteDisabled = (mappingsFieldArrayFields, requiredProperties, target) => {
  if (mappingsFieldArrayFields.filter(p => p.target === target).length > 1) {
    return false;
  }

  return !!requiredProperties.find(p => p.name === target);
};

export interface IdentitiesDetailsProvisioningMappingProps {
  attributes: Attribute[];
  noManagePermission: boolean;
  method: IdpUiModelType;
  bulkSelectionActive: boolean;
  setBulkSelectionActive: Dispatch<SetStateAction<boolean>>;
  handleUpdate: (provisioning: "enabled" | "disabled", data: FieldValues) => void;
}

export default function IdentitiesDetailsProvisioningMapping({
  attributes,
  noManagePermission,
  method,
  bulkSelectionActive,
  setBulkSelectionActive,
  handleUpdate,
}: IdentitiesDetailsProvisioningMappingProps) {
  const { cx, classes } = useStyles();
  const theme = useTheme();

  const [menu, setMenu] = useState<null | {
    index: number;
    deleteDisabled?: boolean;
    anchor: HTMLButtonElement;
  }>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const { form } = useFormContext();

  const pool = form.watch("pool");
  const identifier = form.watch("user.identifier");
  const provisioning = form.watch("provisioning");
  const mapping = form.watch("user.attributes_mapping");

  const listWorkspacePoolsQuery = useListWorkspacePools({
    wid: method.authorization_server_id!,
  });
  const getPoolQuery = useGetPool(pool?.id ?? "", { enabled: !!pool?.id });
  const serverPool = getPoolQuery.data;
  const {
    properties,
    requiredProperties,
    isLoading: propertiesIsLoading,
  } = useProvisioningMappingProperties({
    poolId: pool?.id,
  });

  const mappingsFieldArray = useFieldArray({
    control: form.control,
    name: "user.attributes_mapping",
  });

  const handleAddItem = () => {
    mappingsFieldArray.append({
      source: null,
      target: null,
      type: null,
      allow_weak_decoding: false,
      update_on_sign_in: true,
    });
  };

  const handleRemoveItem = (index: number) => {
    mappingsFieldArray.remove(index);
  };

  const handleToggleRow = (index: number) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter(v => v !== index));
    } else {
      setBulkSelectionActive(true);
      setSelectedRows([...selectedRows, index]);
    }
  };

  const handleBulkUpdateSignIn = (enable: boolean) => {
    selectedRows.forEach(i => {
      form.setValue(`user.attributes_mapping[${i}].update_on_sign_in`, enable);
    });
  };

  const handleBulkDelete = () => {
    const allowedRowsToRemove = selectedRows.filter(
      i =>
        !requiredProperties.find(
          p => p.name === ((mappingsFieldArray.fields as any) || []).at(i)?.target
        )
    );
    mappingsFieldArray.remove(allowedRowsToRemove);
  };

  const idpData = providers.find(p => p.method === method.method);

  const isLoading = getPoolQuery.isLoading || propertiesIsLoading;

  const missingRequiredProperties = useMemo(() => {
    if (isLoading || !pool?.id) {
      return [];
    }

    return requiredProperties
      .map(p => p.name)
      .filter(
        name =>
          !(method.jit?.provisioning?.user?.attributes_mapping || []).find(p => p.target === name)
      )
      .map(name => {
        const property = properties.find(property => property.name === name);

        return {
          source: null,
          target: property?.name,
          type: "string",
          allow_weak_decoding: "string" !== property?.type,
          update_on_sign_in: false,
        };
      });
  }, [
    pool?.id,
    requiredProperties,
    method.jit?.provisioning?.user?.attributes_mapping,
    properties,
    isLoading,
  ]);

  useEffect(() => {
    if (isLoading || !pool?.id) {
      return;
    }

    form.setValue("user.attributes_mapping", [
      ...(missingRequiredProperties || []),
      ...(method.jit?.provisioning?.user?.attributes_mapping || []),
    ]);
  }, [
    pool?.id,
    isLoading,
    missingRequiredProperties,
    method.jit?.provisioning?.user?.attributes_mapping,
    requiredProperties,
    form,
  ]);

  return (
    <>
      <Typography variant="h5" className={classes.header} style={{ marginBottom: 24 }}>
        Attribute Provisioning
      </Typography>
      <Paper style={{ position: "relative" }}>
        <div className={classes.grid}>
          <div style={{ position: "relative" }}>
            <img src={idpData?.icon} alt="ogo" className={classes.logo} />
            <CardWithIconAndTitle
              img={saIcon}
              title={idpData?.name ?? ""}
              subtitle="Incoming User/Authentication Context"
              id="identities-mappings-target-tile"
              noPadding
              noBorder
              style={{ marginLeft: 24, zIndex: 1 }}
              subtitleStyle={{ color: theme.palette.secondary.light, marginLeft: 8 }}
              titleStyle={{ marginLeft: 8 }}
              noIconWidth
            />
          </div>
          <div />
          <div className={classes.flex}>
            <CardWithIconAndTitle
              img={identityPoolIcon}
              title={serverPool?.name ?? ""}
              subtitle={
                listWorkspacePoolsQuery.data?.pools?.length === 1 ? "Users" : "Identity Pool"
              }
              id="identities-mappings-source-tile"
              noPadding
              noBorder
            />

            <IconButton
              icon={Checklist}
              onClick={() => {
                setBulkSelectionActive(!bulkSelectionActive);
                if (!bulkSelectionActive) {
                  setSelectedRows([]);
                }
              }}
              disabled={noManagePermission}
            />
          </div>
        </div>

        {bulkSelectionActive && (
          <BulkActionsToolbar
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            allRowsNumber={mappingsFieldArray.fields.length}
            onDelete={handleBulkDelete}
            onCancel={() => setBulkSelectionActive(false)}
            customActions={[
              {
                onClick: () => handleBulkUpdateSignIn(true),
                icon: RefreshCw,
                label: "Update on sign-in",
              },
              {
                onClick: () => handleBulkUpdateSignIn(false),
                icon: RefreshCw,
                label: "Clear",
                isIconCrossedOut: true,
              },
            ]}
          />
        )}

        <div className={cx(classes.grid, classes.rowDisabled)}>
          <div>
            <Autocomplete
              id="mapping-identifier-0-source"
              options={attributes}
              renderInput={params => (
                <TextFieldMUI {...params} className={classes.identifierAutocomplete} />
              )}
              disabled
              value={identifier.source}
              getOptionLabel={option => attributes.find(o => o.name === option)?.description ?? ""}
              isOptionEqualToValue={(option, value) => option.name === value}
            />
          </div>
          <div className={classes.iconCell}>
            <LogIn color="#10B981" style={{ width: 21 }} />
          </div>
          <div style={{ display: "inline-grid", gridColumnStart: 3, gridColumnEnd: 5 }}>
            <Select
              id="mapping-identifier-0-target"
              items={typeSelectOptions.map(option => ({
                value: option.value,
                label: option.name,
              }))}
              value={identifier.type}
              disabled
              size="small"
              fullWidth
              style={{ minHeight: 48 }}
            />
          </div>
        </div>

        {isLoading && <Progress />}
        {!isLoading &&
          mappingsFieldArray.fields.map(({ id, ...initialValues }, i) => {
            const name = `user.attributes_mapping[${i}]`;
            const source = form.getValues(`${name}.source`);
            const target = form.getValues(`${name}.target`);
            const allowWeakDecoding = form.getValues(`${name}.allow_weak_decoding`);
            const updateOnSignIn = form.getValues(`${name}.update_on_sign_in`);
            const sourceValue = attributes.find(a => a.name === source);
            const targetValue = properties.find(a => a.name === target);
            const isAddressOrIdentifierMappingRow =
              target?.startsWith("addresses.") || target?.startsWith("identifiers.");

            return (
              <div className={classes.grid} key={id}>
                <div>
                  <AuthnContextAutocomplete
                    id={`${name}-source`}
                    name={`${name}.source`}
                    options={attributes}
                    defaultValue={(initialValues as any).source}
                    afterChange={value => {
                      const type = attributes.find(a => a.name === value)?.type || "";
                      const allowWeakDecoding = type !== targetValue?.type;
                      form.setValue(`${name}.type`, type);
                      form.setValue(`${name}.allow_weak_decoding`, allowWeakDecoding);
                    }}
                  />
                </div>
                <div className={classes.iconCell}>
                  <TextField
                    id={`${name}-type`}
                    name={`${name}.type`}
                    defaultValue={(initialValues as any).type}
                    style={{ display: "none" }}
                  />
                  <CheckboxField
                    id={`${name}-allow_weak_decoding`}
                    name={`${name}.allow_weak_decoding`}
                    defaultValue={(initialValues as any).allow_weak_decoding}
                    style={{ display: "none" }}
                  />
                  <Badge
                    badgeContent={
                      !isAddressOrIdentifierMappingRow && allowWeakDecoding ? (
                        <Tooltip
                          title={
                            <>
                              <p>
                                <b>Weak decoding</b>
                              </p>
                              <p>Mapping is made between attributes with different types</p>
                            </>
                          }
                        >
                          <AlertTriangle size={12} />
                        </Tooltip>
                      ) : undefined
                    }
                    classes={{
                      badge: classes.badge,
                    }}
                  >
                    {updateOnSignIn ? (
                      <Tooltip
                        title={
                          <>
                            <p>
                              <b>Updated on each sign-in</b>
                            </p>
                            <p>
                              User attribute in the persistent store will be updated on each user
                              sign-in
                            </p>
                          </>
                        }
                      >
                        <RefreshCw color="#10B981" style={{ width: 20 }} />
                      </Tooltip>
                    ) : (
                      <LogIn color="#10B981" style={{ width: 21 }} />
                    )}
                  </Badge>
                </div>
                <div className={classes.flex}>
                  <AuthnContextAutocomplete
                    id={`${name}-target`}
                    name={`${name}.target`}
                    options={properties}
                    defaultValue={(initialValues as any).target}
                    afterChange={value => {
                      const type = properties.find(a => a.name === value)?.type || "";
                      const allowWeakDecoding = sourceValue?.type !== type;
                      form.setValue(`${name}.allow_weak_decoding`, allowWeakDecoding);

                      if (value?.startsWith("addresses.") || value?.startsWith("identifiers.")) {
                        form.setValue(`${name}.update_on_sign_in`, false);
                      }
                    }}
                    groupBy={option => option.split(".")[0]}
                    renderGroup={params => {
                      return (
                        <li className={classes.groupContainer} key={params.key}>
                          <div className={classes.groupLabel}>
                            {getGroupByLabels(params.group, classes)}
                          </div>
                          <ul className={classes.group}>{params.children}</ul>
                        </li>
                      );
                    }}
                    showName
                    disabled={isModifyOrDeleteDisabled(
                      mapping,
                      requiredProperties,
                      (initialValues as any).target
                    )}
                  />
                  <CheckboxField
                    id={`${name}-update_on_sign_in`}
                    name={`${name}.update_on_sign_in`}
                    defaultValue={(initialValues as any).update_on_sign_in}
                    style={{ display: "none" }}
                  />

                  <div style={{ marginLeft: 32, width: 40, textAlign: "center" }}>
                    {bulkSelectionActive ? (
                      <Checkbox
                        checked={selectedRows.includes(i)}
                        onChange={() => handleToggleRow(i)}
                        style={{ padding: 0 }}
                      />
                    ) : (
                      <IconButton
                        onClick={e => {
                          setMenu({
                            index: i,
                            deleteDisabled: isModifyOrDeleteDisabled(
                              mapping,
                              requiredProperties,
                              (initialValues as any).target
                            ),
                            anchor: e.currentTarget,
                          });
                        }}
                        icon={MoreVertical}
                        disabled={noManagePermission}
                        id={`${name}-more-button`}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}

        <div>
          <Button
            onClick={handleAddItem}
            disabled={noManagePermission || !pool || isLoading}
            variant="outlined"
            style={{ margin: "16px 24px" }}
          >
            + Add row
          </Button>
        </div>
      </Paper>
      <IdentitiesDetailsProvisioningMappingMenu
        menu={menu}
        deleteDisabled={menu?.deleteDisabled}
        onClose={() => setMenu(null)}
        onRemove={handleRemoveItem}
        onSelect={handleToggleRow}
      />

      <FormFooter
        id="jit-form-footer"
        onSubmit={data => {
          handleUpdate(provisioning, data);
          setBulkSelectionActive(false);
        }}
        style={{ marginTop: 32 }}
      />
    </>
  );
}
