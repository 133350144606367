import React, { useEffect, useMemo } from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { map, pathOr, prop } from "ramda";

import { ServerResponse } from "@cloudentity/acp-admin";

import FormAccordion from "../../../common/components/FormAccordion";
import RouteLeavingGuard from "../../../common/components/RouteLeavingGuard";
import AutocompleteField from "../../../common/utils/forms/AutocompleteField";
import Form, { useForm } from "../../../common/utils/forms/Form";
import { useCheckWorkspacePermissions } from "../../services/adminPermissionsQuery";
import CardWithIconAndTitle from "../common/CardWithIconAndTitle";
import { CommonIdpConfig } from "./CommonIdpConfig";
import CommonIdpConfigUpper from "./CommonIdpConfigUpper";
import IdentitiesDetailsFooter from "./IdentitiesDetailsFooter";
import SSOIDPSettings from "./SSOIDPSettings";
import { amrOptions, amrToResponse, getAMRLabel } from "./amrOptions";
import { IdpUiModelGithubType, providers } from "./identities.utils";

const id = "identities-configuration-github-embedded";

interface Props {
  idp: IdpUiModelGithubType;
  server: ServerResponse | undefined;
  updateProgress?: boolean;
  inEdit?: boolean;
  customSubmit?: boolean;
  onInit?: (fn: () => void) => void;
  onCancel?: () => void;
  onSubmit: (data: IdpUiModelGithubType) => void;
  onDelete?: (idp: IdpUiModelGithubType) => void;
}

export default function IdentitiesConfigurationGithubEmbedded({
  idp,
  server,
  updateProgress,
  inEdit,
  customSubmit,
  onCancel,
  onSubmit,
  onInit,
  onDelete,
}: Props) {
  const data = useMemo(() => idp || {}, [idp]);

  const checkWorkspacePermissionsQuery = useCheckWorkspacePermissions(server?.id);

  const form = useForm({
    id,
    initialValues: data,
    progress: updateProgress,
    noManagePermission: !checkWorkspacePermissionsQuery.data?.manage_idps && !customSubmit,
  });

  const submitFn = (idp, data) => {
    return {
      ...idp,
      ...amrToResponse(data),
      ...data,
    };
  };

  const providerMapData = providers.find(p => p.method === "github_embedded");

  useEffect(() => {
    onInit && onInit(() => form.handleSubmit(data => onSubmit && onSubmit(submitFn(idp, data))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const serverSSOEnabled = server?.sso?.enabled ?? false;

  return (
    <Form form={form}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Paper style={{ padding: 32 }}>
            {providerMapData && (
              <CardWithIconAndTitle
                img={providerMapData.icon}
                title={providerMapData.name}
                subtitle={providerMapData.description}
                id={`idp-${providerMapData.name.replace(/ /g, "-")}`}
                style={{ marginBottom: 32, width: "50%" }}
              />
            )}

            <Typography variant="h5" style={{ marginBottom: 48 }}>
              This is a preconfigured IDP that allows logging in with Github.
            </Typography>
            <CommonIdpConfigUpper provider={idp} inEdit={inEdit} onUpdate={onSubmit} />
            <CommonIdpConfig data={idp} />

            {inEdit && (
              <FormAccordion title="Advanced settings" id={id}>
                <AutocompleteField
                  name="static_amr"
                  label="Authentication Method Reference"
                  helperText="If set overwrites AMR obtained from this authentication method"
                  defaultValue={pathOr([], ["static_amr"], idp)}
                  options={map(prop("value"), amrOptions)}
                  getOptionLabel={getAMRLabel}
                  multiple
                />
                {serverSSOEnabled && <SSOIDPSettings />}
              </FormAccordion>
            )}

            <IdentitiesDetailsFooter
              customSubmit={customSubmit}
              idp={idp}
              onSubmit={data => onSubmit && onSubmit(submitFn(idp, data))}
              onCancel={onCancel}
              onDelete={onDelete}
            />
          </Paper>
        </Grid>
        {inEdit && <RouteLeavingGuard />}
      </Grid>
    </Form>
  );
}
