import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "@tanstack/react-query";
import { path } from "ramda";

import { Schema } from "@cloudentity/acp-identity";

import { getTenantId } from "../../../../../common/api/paths";
import ConfirmationDialog from "../../../../../common/components/ConfirmationDialog";
import RouteLeavingGuard from "../../../../../common/components/RouteLeavingGuard";
import {
  notifyError,
  notifyErrorOrDefaultTo,
  notifySuccess,
} from "../../../../../common/components/notifications/notificationService";
import Form, { useForm } from "../../../../../common/utils/forms/Form";
import FormFooter from "../../../../../common/utils/forms/FormFooter";
import TextField from "../../../../../common/utils/forms/TextField";
import TextFieldRequired from "../../../../../common/utils/forms/TextFieldRequired";
import adminIdentitySchemasApi from "../../../../services/adminIdentitySchemasApi";
import {
  getSchemaQueryKey,
  listSchemasQueryKey,
  useGetSchema,
} from "../../../../services/adminIdentitySchemasQuery";
import { useCheckTenantPermissions } from "../../../../services/adminTenantsQuery";

function IdentitySchemaConfiguration() {
  const { id = "" } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [updateProgress, setUpdateProgress] = useState(false);
  const [removeProgress, setRemoveProgress] = useState(false);
  const [schemaToRemove, setSchemaToRemove] = useState<Schema>();

  const checkTenantPermissionsQuery = useCheckTenantPermissions();
  const schemaQuery = useGetSchema(id);

  const data = useMemo(() => schemaQuery.data || {}, [schemaQuery.data]);

  const form = useForm({
    id: "identity-schema",
    initialValues: data,
    progress: updateProgress || removeProgress,
    noManagePermission: !checkTenantPermissionsQuery.data?.create_identity_pool,
  });
  const queryClient = useQueryClient();

  const handleUpdate = (schema: Schema) => {
    setUpdateProgress(true);
    adminIdentitySchemasApi
      .updateSchema({ schID: id, schema: { ...schemaQuery.data, ...schema } })
      .then(res => queryClient.setQueryData(getSchemaQueryKey(getTenantId(), id), res.data))
      .then(() => notifySuccess("Schema updated successfully"))
      .then(() => queryClient.invalidateQueries({ queryKey: listSchemasQueryKey(getTenantId()) }))
      .catch(notifyErrorOrDefaultTo("Error occurred while trying to update schema"))
      .finally(() => setUpdateProgress(false));
  };

  const handleRemove = (schemaId: string) => {
    setRemoveProgress(true);
    adminIdentitySchemasApi
      .deleteSchema({ schID: schemaId })
      .then(() => form.reset(schemaQuery.data, { keepDefaultValues: true }))
      .then(() => queryClient.invalidateQueries({ queryKey: listSchemasQueryKey(getTenantId()) }))
      .then(() => setTimeout(() => navigate("/identity-pools/schemas")))
      .catch(err => {
        if (path(["response", "data", "status_code"], err) === 409) {
          notifyError("Schema cannot be deleted as it's referenced by other resource");
        } else {
          notifyErrorOrDefaultTo("Error occurred while trying to delete schema")(err);
        }

        return err;
      })
      .finally(() => {
        setRemoveProgress(false);
        setSchemaToRemove(undefined);
      });
  };

  return (
    <Form form={form}>
      <Grid container>
        <Grid item xs={7}>
          <Paper style={{ padding: 32 }}>
            <TextFieldRequired name="name" label="Name" disabled={schemaQuery.data?.system} />
            <TextField name="description" label="Description" disabled={schemaQuery.data?.system} />

            {!schemaQuery.data?.system && (
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                {checkTenantPermissionsQuery.data?.create_identity_pool && (
                  <Button
                    variant="text"
                    id="schema-delete-button"
                    color="error"
                    onClick={() => setSchemaToRemove(schemaQuery.data)}
                  >
                    Delete Schema
                  </Button>
                )}

                <FormFooter onSubmit={handleUpdate} />
              </div>
            )}
          </Paper>
        </Grid>
        <RouteLeavingGuard />
      </Grid>

      {schemaToRemove && (
        <ConfirmationDialog
          title="Delete Schema"
          content={
            <>
              You're about to delete the <b>{schemaToRemove.name}</b> schema. This cannot be undone.
              Delete anyway?
            </>
          }
          confirmText="Delete"
          onCancel={() => setSchemaToRemove(undefined)}
          onConfirm={() => {
            if (schemaToRemove?.id) {
              handleRemove(schemaToRemove.id);
            }
          }}
          progress={removeProgress}
        />
      )}
    </Form>
  );
}

export default IdentitySchemaConfiguration;
