import React from "react";
import { useWatch } from "react-hook-form";

import FormSection from "../../../../common/components/FormSection";
import { useFormContext } from "../../../../common/utils/forms/Form";
import SwitchField from "../../../../common/utils/forms/SwitchField";
import TextFieldRequired from "../../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../../common/utils/forms/validation";
import { useFeature } from "../../../../common/utils/hooks/useFeature";

function MfaSettingsArculix() {
  const isArculixFlagEnabled = useFeature("arculix");
  const { form } = useFormContext();

  const isArculixEnabled = useWatch({
    name: "arculix.enabled",
    control: form.control,
  });

  if (!isArculixFlagEnabled) return null;

  return (
    <>
      <FormSection title="Arculix" id="arculix-section">
        <SwitchField
          name="arculix.enabled"
          style={{
            width: "inherit",
            marginBottom: 0,
            display: "flex",
            alignItems: "center",
          }}
        />
      </FormSection>

      {isArculixEnabled && (
        <>
          <TextFieldRequired name="arculix.auth.arculix.client_id" label="Client ID" />

          <TextFieldRequired name="arculix.auth.arculix.client_secret" label="Client Secret" />

          <TextFieldRequired
            name="arculix.settings.arculix.token_endpoint"
            label="Token Endpoint"
            rules={{
              validate: { validUrl: validators.validURL({ label: "Token Endpoint" }) },
            }}
            withLink
          />

          <TextFieldRequired
            name="arculix.settings.arculix.url"
            label="Arculix URL"
            rules={{
              validate: { validUrl: validators.validURL({ label: "Arculix URL" }) },
            }}
            withLink
          />

          <TextFieldRequired
            name="arculix.settings.arculix.faye_url"
            label="Faye URL"
            rules={{
              validate: { validUrl: validators.validURL({ label: "Faye URL" }) },
            }}
            withLink
          />

          <TextFieldRequired
            name="arculix.settings.arculix.dbfp_url"
            label="DBFP URL"
            rules={{
              validate: { validUrl: validators.validURL({ label: "DBFP URL" }) },
            }}
            withLink
          />
        </>
      )}
    </>
  );
}

export default MfaSettingsArculix;
