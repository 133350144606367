import React from "react";

import { Attribute } from "@cloudentity/acp-admin";

import Dialog from "../../../common/components/Dialog";
import Form, { useForm } from "../../../common/utils/forms/Form";
import FormFooter from "../../../common/utils/forms/FormFooter";
import SelectField from "../../../common/utils/forms/SelectField";
import TextFieldRequired from "../../../common/utils/forms/TextFieldRequired";
import { validators } from "../../../common/utils/forms/validation";
import { attributesDataTypeOptions } from "./attrbutesDataTypeOptions";

interface Props {
  attributes: Attribute[];
  onCreate: (attribute: Attribute) => void;
  onCancel: () => void;
  progress: boolean;
}

export default function AuthNContextAddAttribute({
  attributes,
  onCreate,
  onCancel,
  progress,
}: Props) {
  const form = useForm({ id: "authn-context-add-attribute", progress });

  return (
    <Dialog onClose={onCancel} id="add-scope-dialog" title="Add attribute">
      <Form form={form}>
        <TextFieldRequired
          name="name"
          label="Name"
          rules={{
            validate: {
              notUniq: validators.notUniq({ label: "Name", options: attributes.map(a => a.name) }),
            },
          }}
          autoFocus
        />
        <SelectField
          name="type"
          label="Data type"
          options={attributesDataTypeOptions}
          rules={{
            required: "Data type is required",
          }}
        />
        <TextFieldRequired name="description" label="Description" />

        <FormFooter onCancel={onCancel} onSubmit={onCreate} submitText="Create" />
      </Form>
    </Dialog>
  );
}
